import { faMap, faPen, faTrash } from '@fortawesome/pro-light-svg-icons';
import { AddressInfo } from 'types';
import IconFA from 'components/common/IconFA';
import SoftBadge from 'components/common/SoftBadge';
import CopyButton from 'components/MantineUI/Buttons/CopyButton';
import { onCopyToClipBoard, phoneFormatter } from 'helpers/utils';
import { t } from 'i18next';
import { Badge, Flex, Text } from '@mantine/core';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';

type Props = {
  address: AddressInfo;
  className?: string;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
};

const AddressInfoComponent = ({
  address,
  className,
  onClickEdit,
  onClickDelete,
}: Props) => {
  const formattedPhone = phoneFormatter(address?.phone);

  return (
    <div className={className}>
      <div className="flex gap-2 mb-1 items-center">
        <Text fw={600} size="sm">
          {address?.f_name} {address?.l_name}
        </Text>
        <SoftBadge pill bg={'info'} className="fs--2">
          {address?.type === 'business'
            ? t('orders.business_address')
            : t('orders.residential_address')}
        </SoftBadge>
      </div>

      <div className="flex flex-row items-center justify-between mb-1">
        <Text size="sm" c={'gray.7'}>
          {address?.location}
        </Text>
        {/* <Flex></Flex> */}
      </div>
      <Text size="sm" c={'gray.7'}>
        {formattedPhone}
      </Text>
      <Flex align={'center'}>
        <div className="flex-1">
          {address?.is_default ? (
            <Badge bg="gray.3" c={'gray.7'}>
              {t('general.default')}
            </Badge>
          ) : null}
        </div>
        <Flex>
          {onClickEdit ? (
            <IconFA color="gray" onClick={onClickEdit} icon={faPen} />
          ) : null}
          {onClickDelete ? (
            <IconFA color="gray" onClick={onClickDelete} icon={faTrash} />
          ) : null}
          <IconFA
            color="gray"
            onClick={() =>
              window.open(
                `https://maps.google.com?q=${address?.location}`,
                '_blank',
              )
            }
            icon={faMap}
          />
          {address?.location && <CopyButton value={address?.location} />}
        </Flex>
      </Flex>
    </div>
  );
};
export default AddressInfoComponent;
