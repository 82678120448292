const en = {
  translation: {
    admin_panel: 'Admin panel',
    seller_panel: 'Seller panel',
    error: {
      error_404: 'Error 404',
      error_404_message: "The page you're looking for is not found.",
      error_404_content:
        "Make sure the address is correct and that the page hasn't moved. If you think this is a mistake,",
      contact_us: 'contact us.',
      back_home: 'Back home',
      error_403: 'Error 403',
      error_403_message: 'Access denied.',
      error_403_content:
        "Oops, you don't have permission to access this page. If you think this is a mistake,",
      error_500: 'Error 500',
      error_500_message: ' Whoops, something went wrong!',
      error_500_content:
        'Try refreshing the page, or going back and attempting the action again. If this problem persists,',
    },
    navigation: {
      home: 'Home',
      language: 'Tiếng Việt',
      help_center: 'Help center',
      carts: 'Carts',
      cart_details: 'Cart details',
      edit_cart: 'Edit cart',
      add_cart: 'Add cart',
      promotions: 'Promotions',
      promotion: 'Promotion',
      coupons: 'Coupons',
      coupon_details: 'Coupon details',
      edit_coupon: 'Edit coupon',
      add_coupon: 'Add coupon',
      announcements: 'Announcements',
      banners: 'Banners',
      banner_details: 'Banner details',
      edit_banner: 'Edit banner',
      flash_sale: 'Flash sales',
      stock_clearance: 'Stock clearance',
      just_arrived: 'Just arrived',
      top_picks: 'Top picks',
      discounts: 'Discounts',
      discount_details: 'Discount details',
      edit_discount: 'Edit discount',
      customers: 'Customers',
      customer_details: 'Customers details',
      users: 'Users',
      user_details: 'User details',
      sellers: 'Sellers',
      seller_details: 'Seller details',
      postings: 'Postings',
      posts: 'Posts',
      jobs: 'Nail jobs',
      techs: 'Tech profiles',
      salons: 'Salons for sale',
      skills: 'Skills',
      events: 'Events',
      event_signups: 'Event signups',
      settings: 'Settings',
      staff: 'Staff',
      staff_details: 'Staff details',
      shipping: 'Shipping',
      taxes: 'Taxes',
      pages: 'Pages',
      information: 'Information',
      faqs: 'FAQs',
      warehouses: 'Warehouse',
      help_articles: 'Help articles',
      articles: 'Articles',
    },
    register: {
      form: {
        first_name: 'First name',
        last_name: 'Last name',
        store_name: 'Store name',
        store_logo: 'Store logo',
        store_address: 'Store address',
        store_phone: 'Store phone',
        store_email: 'Store email',
        phone: 'Phone',
        mobile: 'Mobile phone',
        address: 'Address',
        email: 'Email',
        password: 'Password',
        department: 'Department',
        confirm_password: 'Confirm password',
        new_password: 'New password',
        confirm_new_password: 'Confirm new password',
        current_password: 'Current password',
        verification_code: 'Verification code',
        account_registration: 'Account',
        seller_information: 'Seller information',
        email_verification: 'Email verification',
        done: 'Done',
        register_content: `Please provide your account information.`,
        read_agree: `I've read and agree to the`,
        seller_agreement: 'Seller Agreement',
        verify_email: 'Verify email',
        create_account: 'Create account',
        back: 'Back',
        please_otp:
          'Please enter the 4-digit verification code sent to your email',
        didnt_otp: `Didn't get the code?`,
        resend_otp: 'Resend',
        thank_you: 'Thank you for joining as Nailzy seller!',
        our_team:
          'A representative will reach out to you in the coming days to help you get started with the onboarding process. Stay tuned!',
        business_type: 'Business type',
        company: 'Company',
        individual: 'Individual',
        legal_business_name: 'Legal business name',
        tax_id: 'Tax ID (EIN, ITIN, or SSN)',
        business_email: 'Business email',
        email_existed: 'Email existed',
      },
      sell_on: 'Sell on Nailzy.com marketplace',
      become_our: 'Become Nailzy',
      selling_partner: 'selling partner',
      create_a: 'Create a',
      free: 'FREE',
      seller_account: 'seller account in only',
      simple: '3 simple steps',
      to_sell: 'to sell your products on our platform and get paid.',
      why_sell: 'Why Sell on Nailzy.com Marketplace?',
      why_sell_content:
        'Nailzy.com is a pioneer app providing a one-stop-shop for all things related to the nail industry: find a job, hire a tech, or sell nail salons. Nailzy marketplace is the Amazon of the nail industry, providing manufacturers and suppliers of nail products with a platform to promote and sell their products.',
      fast: 'Fast & free to join',
      fast_content:
        'At Nailzy.com, we offer a seamless process for opening an online store. Our registration process is completely free and easy.',
      increased: 'Increased visibility & reach',
      increased_content:
        'List your nail products on Nailzy.com for targeted visibility and reach to a larger audience of professionals and enthusiasts.',
      easy: 'Easy to use',
      easy_content:
        'Easily manage product listings, inventory, and sales from any device with our user-friendly platform for on-the-go store management.',
      marketing: 'Marketing & promotions',
      marketing_content:
        'Boost your sales with our marketing tools by creating discounts, promotions, and campaigns, reaching a wider customer base.',
      customer: 'Customer service & support',
      customer_content:
        'We provide exceptional customer service and support, with a dedicated team and comprehensive knowledge base to help you.',
      unique: 'Unique features',
      unique_content:
        'We offer many features for nail professionals and businesses, including job postings, technician profiles, salon sales, and classes.',
      how_to_sell: 'How to sell on Nailzy.com marketplace',
      step_1: 'Register a seller account',
      step_1_1: 'Fill out your account information',
      step_1_2: 'Provide your business documents',
      step_1_3: 'Get approved and start selling',
      step_2: 'List products',
      step_2_1: 'Upload a single product',
      step_2_2: 'Import bulk products',
      step_3: 'Manage orders',
      step_3_1: 'Get notified when you receive an order',
      step_3_2: 'Process orders and print shipping labels',
      step_3_3: 'Ship your items to the customer',
      step_4: 'Get paid',
      step_4_1: 'Get a monthly report of your sales',
      step_4_2: 'Get paid via direct deposit or checks',
      contact: 'Contact seller support',
      calculate: 'Calculate selling fees',
      there_are: 'There are',
      zero_fees: 'ZERO platform fee',
      and_only: 'and only',
      for_your: 'for your first 3 months of selling on Nailzy.',
      after_that: `After that, we'll charge`,
      platform: '10% for the platform fee',
      and: 'and',
      transaction: '3% for the transaction fee',
      each_order: 'for each order. ',
      the_fees:
        'The fees are calculated based on your total sales after accounting for any discounts, shipping fees, and sales taxes.',
      subtotal: 'Subtotal',
      discount: 'Discount',
      shipping: 'Shipping',
      sales_tax: 'Sales tax',
      order_total: 'Order total',
      platform_fee: 'Platform fee 10%',
      transaction_fee: 'Transaction fee 3%',
      your_payout: 'Your payout',
      net_sales: 'Net sales',
    },
    login: {
      logout: 'Log out',
      register: 'Register',
      login: 'Log in',
      admin_login: 'Admin & staff log in',
      admin_please: 'Please log in to access the admin panel.',
      seller_login: 'Seller log in',
      seller_please: 'Please log in to access the seller panel.',
      forgot_password: 'Forgot password?',
      no_account: `Don't have a seller account?`,
      register_free: 'Register for free',
      already_seller: 'Already a seller?',
      signed_out: 'Logged out',
      thank_you: 'Thank you for using Nailzy!',
      back_login: 'Back to log in',
      login_with_password: 'Log in with password',
      login_without_password: 'Log in without password',
      enter_verification_code: 'Please enter the verification code',
      code_sent_to: 'A code has been sent to',
      or_continue_with: 'Or continue with',
    },
    forgot_password: {
      forgot_password: 'Forgot password',
      reset_password: 'Reset password',
      please_enter:
        'Please enter your email below to receive a verification code.',
      send_code: 'Send code',
      please_code:
        'Please enter the 4-digit verification code sent to your email',
      create_new: 'and create your new password.',
      password_reset: 'Your password is reset!',
      you_can: 'You can now',
      login_to: 'log in to your account',
      a_mail_sent_to_reset_password:
        'Please check your mailbox to reset your password',
    },
    account: {
      account: 'Account',
      store_details: 'Store details',
      sales_rep_code: 'Sales rep code',
      bank_details: 'Bank details',
      bank_name: 'Bank name',
      account_name: 'Account holder name',
      account_number: 'Account number',
      routing_number: 'Routing number',
      security: 'Security',
      change_password: 'Change password',
      security_desc: 'You can add or update your sign-in methods.',
      social_links: 'Social links',
      social_links_desc:
        'Sign in faster by linking your social media to your account.',
      profile_security: 'Profile & Security',
      store_desc: 'Please provide your store information.',
    },
    dashboard: {
      dashboard: 'Dashboard',
      welcome: 'Welcome',
      greeting: "Here's a summary of your store's performance today.",
      today_sales: "Today's sales",
      today_orders: "Today's orders",
      total_products: 'Total products',
      total_products_desc: 'Total number of listed products',
      total_orders: 'Total orders',
      total_orders_desc: 'The total number of paid orders',
      total_payouts: 'Total payouts',
      total_payouts_desc: 'Total amount paid out to you',
      total_customers: 'New accounts',
      total_customers_desc: 'The total number of new registered accounts',
      total_jobs: 'Total nail jobs',
      total_jobs_desc: 'Total number of posted jobs',
      total_techs: 'Total tech profiles',
      total_techs_desc: 'Total number of posted techs',
      total_salons: 'Total salons for sale',
      total_salons_desc: 'Total number of posted salons',
      total_events: 'Total events',
      total_events_desc: 'Total number of posted events',
      best_selling: 'Best selling products',
      items_sold: 'Items sold',
      items_sold_decs: 'The number of products sold',
      unit_price: 'Unit price',
      total_sales: 'Total sales',
      total_sales_desc: 'The total amount of sales',
      total_posts: 'Total posts',
      total_posts_desc: 'Total number of posts',
      store_visits: 'Store visits',
      store_visits_decs: 'The number of visits to your store',
      product_views: 'Product views',
      product_views_decs: 'The total number of unique product views',
      conversion_rate: 'Conversion rate',
      conversion_rate_decs:
        'The percentage of product views that result in a purchase',
      thing_to_do: 'Things to do',
      need_accept: 'Orders need to be accepted',
      waiting_payment: 'Orders are waiting for payment',
      need_fulfill: 'Orders need to be fulfilled',
      need_approve: 'Product reviews need to be approved',
      best_seller_items: 'Best selling items',
      notifications: 'Notifications',
      marketing_center: 'Marketing center',
      last_x_day: 'Last {{days}} days',
      custom: 'Custom',
      total_paid_orders: 'Paid orders',
      total_paid_orders_desc: 'The total number of paid orders',
      total_paid_sales: 'Paid sales',
      total_paid_sales_desc: 'The total value of paid orders',
      total_unpaid_orders: 'Unpaid orders',
      total_unpaid_orders_desc:
        'The total number of unpaid orders (including awaiting payment, payment failed, and unpaid orders)',
      total_unpaid_sales: 'Unpaid sales',
      total_unpaid_sales_desc:
        'The total value of unpaid orders (including awaiting payment, payment failed, and unpaid orders)',
      order_analytics: 'Order analytics',
      customer_analytics: 'Customer analytics',
      total_buyer: 'Total buyers',
      total_buyer_desc: 'The total number of customers who have made purchases',
      total_returning_buyer: 'Returning buyers',
      total_returning_buyer_desc:
        'The total number of customers who have made more than one purchase',
      total_potential_buyer: 'Potential buyers',
      total_potential_buyer_desc:
        'The total number of customers who have placed orders but not yet completed payments',
    },
    general: {
      or: 'or',
      at: 'at',
      all: 'All',
      search: 'Search...',
      start_date: 'From',
      end_date: 'Until',
      created_date: 'Created on',
      created_by: 'Created by',
      updated_date: 'Updated on',
      published_date: 'Published on',
      status: 'Status',
      active: 'Active',
      draft: 'Draft',
      sent: 'Sent',
      canceled: 'Canceled',
      scheduled: 'Scheduled',
      deleted: 'Deleted',
      closed: 'Closed',
      approved: 'Approved',
      pending: 'Pending',
      rejected: 'Rejected',
      completed: 'Completed',
      previous: 'Previous',
      next: 'Next',
      rows_per_page: 'Rows per page',
      from: 'From',
      to: 'to',
      of: 'of',
      selected: 'selected',
      bulk_actions: 'Bulk actions',
      change_status: 'Change status',
      apply: 'Apply',
      applying: 'Applying...',
      edit: 'Edit',
      delete: 'Delete',
      deleting: 'Deleting...',
      remove: 'Remove',
      removing: 'Removing...',
      export: 'Export',
      exporting: 'Exporting...',
      import: 'Import',
      importing: 'Importing...',
      cancel: 'Cancel',
      discard: 'Discard',
      close: 'Close',
      save: 'Save',
      saving: 'Saving...',
      reset: 'Reset',
      view: 'View',
      export_desc: 'Do you want to export',
      export_desc_2: 'on the current page to a spreadsheet file (XLSX format)?',
      import_desc: 'in bulk',
      import_desc_2:
        ' a spreadsheet template (XLSX format) and fill out with correct information.',
      import_desc_3:
        'Then you can upload your XLSX file below to do bulk import',
      upload_import: 'Upload to import',
      download: 'Download',
      upload_images: 'Upload images',
      upload_images_desc:
        'You can upload a compressed file in ZIP format containing your images below.',
      upload_images_desc_2:
        'The filename of the images must be matched with data of the column "Filename" in the bulk import template.',
      upload: 'Upload',
      uploading: 'Uploading...',
      loading: 'Loading...',
      notes: 'Notes',
      add_note: 'Add note',
      edit_note: 'Edit note',
      content: 'Content',
      type_here: 'Type here...',
      delete_confirm: 'Are you sure you want to ',
      delete_confirm_2: 'Are you sure you want to delete',
      cant_undone: '? This can’t be undone.',
      filter: 'Filter',
      sort: 'Sort',
      view_web: 'View on website',
      images: 'Images',
      browse: 'Browse',
      images_desc: 'or drag and drop your image here',
      images_desc_2: 'Maximum 10 files to be uploaded',
      discard_all: 'Discard unsaved changes',
      discard_all_desc:
        'Are you sure you want to discard unsaved changes? You’ll lose any information you have entered.',
      name_asc: 'Name: A to Z',
      name_desc: 'Name: Z to A',
      code_asc: '#: A to Z',
      code_desc: '#: Z to A',
      rating_desc: 'Rating: Highest first',
      rating_asc: 'Rating: Lowest first',
      created_desc: 'Created: Newest first',
      created_asc: 'Created: Oldest first',
      updated_desc: 'Updated: Newest first',
      updated_asc: 'Updated: Oldest first',
      update_success: 'Updated successful!',
      update_fail: 'Updated failed!',
      reorder: 'Re-order',
      create_success: 'Created successful!',
      create_fail: 'Created failed!',
      slug_exist: 'Slug is existed',
      arrange: 'Arrange',
      add: 'Add',
      name: 'Name',
      image: 'Image',
      slug: 'Slug',
      icon: 'Icon',
      back: 'Back',
      select: 'Select',
      continue: 'Continue',
      copied: 'Copied',
      copy_failed: 'Failed to copy',
      write: 'Write',
      confirm: 'Confirm',
      update: 'Update',
      description: 'Descriptions',
      official_brand: 'Official brand',
      generate_code: 'Generate code',
      upload_and_save: 'Upload & save',
      main_image: 'Main image',
      added: 'Added',
      link: 'Link',
      unlink: 'Unlink',
      profile_picture: 'Profile picture',
      banner: 'Banner',
      drop_files: 'Drop your files here',
      code: 'Code',
      type: 'Type',
      percentage: 'Percentage',
      fixed: 'Fixed',
      value: 'Value',
      create: 'Create',
      select_icon: 'Select icon',
      select_date_time: 'Select date & time',
      select_all: 'Select all',
      validity_period: 'Validity period',
      quantity: 'Quantity',
      verify: 'Verify',
      verifying: 'Verifying...',
      no_data: 'No data...',
      phone: 'Phone number',
      view_more: 'View more',
      view_less: 'View less',
      edit_history: 'Edit history',
      by: 'by',
      delete_success: 'Deleted successful!',
      delete_fail: 'Deleted failed!',
      days_ago: 'days ago',
      last_update_on: 'Last updated on',
      joined_on: 'Joined',
      month_ago: 'months ago',
      title: 'Title',
      to_be_confirmed: 'To be confirmed',
      select_date_range: 'Select date range',
      select_payment_status: 'Select payment status',
      select_order_status: 'Select order status',
      select_role: 'Select role',
      page: 'Page',
      limit: 'Limit',
      export_success: 'Exported successful!',
      export_fail: 'Exported failed!',
      import_success: 'Imported successful!',
      import_fail: 'Imported failed!',
      make_as_default: 'Make as default',
      default: 'Default',
      featured: 'Featured',
      select_type: 'Select type',
      ships_from_nailzy: 'Ships from Nailzy',
      current: 'Current',
      change: 'Change',
      duplicate: 'Duplicate',
      dropzone_decs_1: 'Drag images here or click to select files',
      dropzone_decs_2:
        'Attach as many files as you like, each file should not exceed 10mb',
      team: 'Team',
    },
    push_notifications: {
      push_notifications: 'Push notifications',
      send_notification: 'Send push notification',
      title: 'Title',
      message: 'Message',
    },
    orders: {
      marketplace: 'Marketplace',
      sales: 'Sales',
      orders: 'Orders',
      order: 'Order',
      order_details: 'Order details',
      edit_order: 'Edit order',
      no_order: 'No order found...',
      order_date: 'Order date',
      order_date_range: 'Order date range',
      customer: 'Customer',
      total: 'Total',
      quantity: 'Quantity',
      shipping: 'Shipping',
      free_shipping: 'Free shipping',
      payment_status: 'Payment status',
      order_status: 'Order status',
      fulfillment_status: 'Fulfillment status',
      view_order: 'View order',
      fulfill_order: 'Fulfill order',
      fulfill_type: 'Fulfillment type',
      refulfill_order: 'Re-fulfill order',
      accept: 'Accept',
      buy_shipping_label: 'Buy shipping label',
      print_invoice: 'Print invoice',
      print_packing_list: 'Print packing list',
      send_order_status: 'Send order status via SMS',
      items: 'Items',
      item: 'Item',
      paid: 'Paid',
      unpaid: 'Unpaid',
      awaiting_payment: 'Awaiting payment',
      partially_paid: 'Partially paid',
      payment_failed: 'Payment failed',
      refunded: 'Refunded',
      refund: 'Refund',
      reason: 'Reason',
      pending: 'Pending',
      awaiting_fulfillment: 'Awaiting fulfillment',
      partially_shipped: 'Partially shipped',
      shipped: 'Shipped',
      completed: 'Completed',
      canceled: 'Canceled',
      store: 'Store',
      change_status: 'Change status',
      select_customer: 'Select customer',
      select_coupons: 'Select coupons',
      select_status: 'Select status',
      send_payout: 'Send payout',
      product: 'Product',
      unit_price: 'Unit price',
      amount: 'Amount',
      variant: 'Variant',
      sku: 'SKU',
      subtotal: 'Subtotal',
      sales_tax: 'Sales tax',
      all_sellers: 'All sellers',
      discounts: 'Discounts',
      taxes: 'Taxes',
      grand_total: 'Order total',
      order_comments: 'Order notes',
      payment_method: 'Payment method',
      shipping_method: 'Shipping method',
      please_fetch_data: 'Please fetch data',
      billing_address: 'Billing address',
      shipping_address: 'Shipping address',
      address_book: 'Address book',
      flat_rate: 'Flat Rate',
      order_all: 'All',
      order_pending: 'Pending',
      order_processing: 'Processing',
      order_partially_shipped: 'Partially shipped',
      order_shipped: 'Shipped',
      order_delivered: 'Delivered',
      fail_delivered: 'Fail delivered',
      order_complete: 'Completed',
      order_canceled: 'Canceled',
      process_order: 'Process order',
      items_total: 'Item(s) total',
      items_discount: 'Item(s) discount',
      coupon_discount: 'Coupon discount',
      dup_coupon_code: 'Duplicate coupon',
      total_pricing: 'Total',
      order_process: 'Order timeline',
      est_delivery: 'Estimated delivery',
      business_days: 'business days',
      residential_address: 'Residential',
      business_address: 'Business',
      you_want_accept_order: 'Do you want to accept this order?',
      accept_order: 'Accept order',
      accept_shipping: 'Fulfill order',
      send_noti_to_customer: 'Send notification to customer',
      you_want_cancel_order: 'Do you want to cancel this order?',
      cancel_order: 'Cancel order',
      cancel_and_refund: 'Cancel and refund',
      order_number: 'Order #',
      seller_coupon: 'Seller coupon',
      nailzy_coupon: 'Nailzy coupon',
      package_info: 'Package details',
      tracking_number: 'Tracking number',
      size: 'Dimensions',
      mass: 'Total weight',
      actions: 'Actions',
      shipping_information: 'Shipping information',
      already_bought_label:
        'You have already bought the below shipping label on',
      already_chose_label:
        'You have already selected the below shipping rate on',
      already_entered_custom:
        'You have already entered the below custom fulfillment on',
      business_day: 'business day(s)',
      void_label: 'Void shipping label',
      print_shipping_label: 'Print shipping label',
      already_entered_tracking_info:
        'You have already entered the below tracking information on',
      edit_tracking_info: 'Edit tracking information',
      enter_tracking_info: 'Enter tracking information',
      enter_custom_fulfillment: 'Enter custom fulfillment',
      package_and_weight: 'Package and weight',
      package: 'Package',
      total_weight_with_package: 'Total weight (with package)',
      manage_package: 'Manage package',
      shipping_rates: 'Shipping rates',
      shipping_label: 'Shipping label',
      fetching_shipment: 'Fetching shipping rates...Please wait!',
      fetching_pricing: 'Fetching total pricing...Please wait!',
      summary: 'Summary',
      please_provide_shipping: 'Please provide shipping information',
      enter_order_note: 'Enter order note',
      packing_list_will_print:
        'The packing list will be printed immediately after clicking confirm',
      shipping_carrier: 'Shipping carrier',
      edit_history: 'Edit history',
      provider: 'Provider',
      products: 'Products',
      edit_shipping: 'Edit tracking information',
      fulfilling_shipment: 'Fulfilling order...Please wait!',
      write_comment: 'Write comment',
      choose_shipping_rate: 'Choose shipping rate',
      edit_shipping_rate: 'Edit shipping rate',
      customer_selected_method: 'Customer selected this method at checkout',
      fraud_analysis: 'Fraud analysis',
      high_risk: 'High risk',
      normal_risk: 'Normal risk',
      payment_information: 'Payment information',
      void_information: 'Void tracking information',
      out_of_stock: 'Out of stock',
      customer_request: 'Customer request',
      payment_error: 'Payment error',
      other: 'Other',
      abandoned_checkout: 'Abandoned checkout',
      custom_note: 'Notes',
      edit_shipping_information: 'Edit shipping information',
      sync_tracking_status: 'Sync tracking status',
      mark_as_delivered: 'Mark order as delivered',
      custom_fulfillment: 'Custom fulfillment',
      edit_custom_fulfillment: 'Edit custom fulfillment',
      void_custom_fulfillment: 'Void custom fulfillment',
      shipment: 'Shipment type',
      select_shipment: 'Select fulfillment type',
      shipment_manual: 'Entered tracking information',
      shipment_shippo: 'Bought shipping label',
      shipment_custom: 'Entered custom fulfillment',
      export_orders: 'Export orders',
      current_page: 'Current page',
      select_items: 'Select {{total}} items',
      order_success: 'Order placed successfully!',
      payment_success: 'Payment successfully!',
      order_without_paying: 'Create order without paying',
      pay_with_credit: 'Pay with credit card',
      send_payment_link: 'Send payment link to customer',
      please_select_shipping: 'Please select shipping method',
      minimum_place_order: 'Minimum order amount is $0.5',
      source_web: 'Website',
      source_app: 'Mobile app',
      source_admin: 'Admin panel',
      source_from: 'Order source',
    },
    payouts: {
      finances: 'Finances',
      balance: 'Balance',
      balance_details: 'Balance by orders',
      balance_seller: 'Seller',
      payouts: 'Payouts',
      payout: 'Payout',
      pay: 'Pay',
      payout_details: 'Payouts of order',
      no_payout: 'No payout...',
      completed_date: 'Completed date',
      platform_fee: 'Platform fee 10%',
      transaction_fee: 'Transaction fee 3%',
      net: 'Net',
      paid: 'Paid',
      transaction_id: 'Transaction ID',
      paid_date: 'Paid date',
    },
    products: {
      inventory: 'Inventory',
      products: 'Products',
      product: 'Product',
      product_details: 'Product details',
      edit_product: 'Edit product',
      add_product: 'Add product',
      categories: 'Categories',
      category: 'Category',
      subcategories: 'Subcategories',
      subcategory: 'Subcategory',
      brands: 'Brands',
      brand: 'Brand',
      stores: 'Stores',
      store: 'Store',
      select_brand: 'Select brand',
      select_store: 'Select store',
      select_category: 'Select category',
      select_seller: 'Select seller',
      select_subcategory: 'Select subcategory',
      no_product: 'No product...',
      no_category: 'No category...',
      no_information: 'No information...',
      no_sub_category: 'No subcategory...',
      no_promotion: 'No promotion...',
      variants: 'Variants',
      variant: 'Variant',
      sku: 'SKU',
      sold: 'Sold',
      ratings: 'Ratings',
      similar_products: 'Similar products',
      product_reviews: 'Product reviews',
      product_status: 'Product status',
      product_name: 'Product name',
      product_slug: 'Product slug',
      brand_desc:
        'Select GENERIC for unbranded products. Please contact us if you want to add your brand.',
      product_descriptions: 'Product descriptions',
      product_variants: 'Product variants',
      add_variant: 'Add another variant',
      variant_name: 'Variant name',
      availability: 'Availability',
      availability_desc:
        'To hide this variant on your store, unselect "Available on store".',
      visible_marketplace: 'Visible on Marketplace',
      available_to_buy: 'Available on store',
      allow_purchase: 'Allow purchase',
      price: 'Price',
      original_price: 'Original price',
      original_price_desc: `To show a discounted price, move the product’s original price into "Original price". Enter a lower value into "Price".`,
      variant_sku: 'SKU (Stocking Keeping Unit)',
      variant_barcode: 'Barcode (UPC, GTIN, ISBN, etc.)',
      barcode: 'Barcode',
      inventory_count: 'Inventory count',
      inventory_low_level: 'Inventory low level',
      inventory_low_level_desc: `To show a low-stock message "Hurry up! Only X items left", enter a value into "Inventory count", and a lower value into "Inventory low level".`,
      weight: 'Weight',
      weight_desc: 'Used to calculate shipping costs at checkout.',
      dimensions: 'Dimensions',
      dimensions_lwh: 'Dimensions (Length, Width, Height)',
      material: 'Material',
      size: 'Size',
      delete_product: 'Delete product',
      banner: 'Banner',
      quantity: 'Quantity',
      inventory_adjustment: 'Inventory adjustment',
      warehouse: 'Warehouse',
      shipping: 'Shipping',
      stock_item_type_add: 'Add',
      stock_item_type_deduct: 'Deduct',
      stock_item_type_update: 'Update',
      reason: 'Reason',
      stock_item_activity_initial_inventory: 'Initial inventory',
      stock_item_activity_count: 'Count',
      stock_item_activity_correction: 'Correction',
      stock_item_activity_transfer: 'Transfer',
      stock_item_activity_damage: 'Damage',
      stock_item_activity_returns: 'Return',
      stock_item_activity_other: 'Other',
      order_created: 'Order created',
      order_canceled: 'Order canceled',
      date: 'Date',
      activity: 'Activity',
      stock: 'Stock',
      variant_description: 'Variant descriptions',
      variant_slug: 'Variant slug',
      pricing: 'Pricing',
      arrange_variants: 'Arrange variants',
      export_import: 'Export & Import',
      export: 'Export',
      import: 'Import',
      export_product_desc:
        'Use pre-defined templates for bulk product updates efficiently.',
      import_product_desc_1: 'Max size: 5 MB only in XLSX, XLS, or CSV file.',
      import_product_desc_2:
        'Please upload the latest template to avoid import errors.',
      import_product_desc_3:
        'Please do not edit your product until the tasks have been completed.',
      template: 'Template',
      generate_export: 'Generate export',
      start_import_flow: 'Start import flow',
      import_pricing: 'Pricing',
      import_inventory: 'Inventory',
      pricing_desc: `To update product pricing information: "Price" and "Original price"`,
      inventory_desc: `To update stock inventory information: "Stock quantity"`,
      product_id: 'Product ID',
      variant_id: 'Variant ID',
      correction: 'Correction',
      count: 'Count',
      transfer: 'Transfer',
      damage: 'Damage',
      returns: 'Returns',
      other: 'Other',
      purchase_limit_per_type_day: 'per day',
      purchase_limit_per_type_week: 'per week',
      purchase_limit_per_type_month: 'per month',
      purchase_limit: 'Purchase limit',
      purchase_limit_desc:
        'Set a limit how many a customer can buy within a certain period.',
    },
    promotions: {
      type: 'Type',
      promotion_name: 'Promotion name',
      status: 'Status',
      status_type: 'Time status',
      apply_time: 'Apply time',
      promotion_sku: 'Items',
      banner: 'Banners',
      arrange_banners: 'Arrange banners',
      add_banner: 'Add banner',
      add_sale: 'Add flash sale',
      discount: 'Flash sales',
      combo: 'Combo deals',
      coupon_code: 'Coupons',
      item_discount: 'Item discounts',
      shipping_discount: 'Shipping discounts',
      bonus_gift: 'Bonus gifts',
      title: 'Title',
      sub_title: 'Subtitle',
      description: 'Description',
      banner_desc: 'Banner will be displayed in the marketplace homepage.',
      discount_desc:
        'Flash sale is a limited-time discount on a product or products.',
      combo_desc:
        'Combo is a set of products that are sold together at a discount.',
      coupon_code_desc:
        'A coupon code is a string of characters that customers can enter at checkout to apply a discount to their order.',
      item_discount_desc:
        'A discount on a product or products, which can be applied to a specific product or all products in a category.',
      shipping_discount_desc:
        'A discount on shipping, which can be applied to all shipping methods or specific shipping methods.',
      bonus_gift_desc:
        'A free gift that is added to the cart when a customer purchases a specific product or spends a certain amount.',
      find_by_name: 'Search by item name or SKU',
      find_by_cate: 'Find by category',
      find_by_brand: 'Find by brand',
      find_by_seller: 'Find by seller',
      product_list: 'Product list',
      selected_products: 'Seleted items',
      original_price: 'Original price',
      discount_price: 'Discount price',
      available_stock: 'Available stock',
      total_item: 'Total item',
      item_per_order: 'Limit item',
      delete_item: 'Delete',
      create_promotion: 'Create new promotions',
      promotion_slug: 'Promotion slug',
      save_info: 'Save information',
      save_and_select_product: 'Save and select products',
      start_date: 'Valid from',
      end_date: 'Valid until',
      all_selected_promotions: 'All selected promotions',
      remove_items: 'Remove {{items}} items',
      existing_variants: 'Items already in another promotion',
      discount_amount: 'Discount amount',
      selling_price: 'Selling price',
      select_products: 'Select products',
    },
    form: {
      common: {
        field_logo: '{{field}} logo',
        field_name: '{{field}} name',
        field_code: '{{field}} code',
        field_status: '{{field}} status',
        drop_file_here: 'Drop your files here',
        type: 'Type',
      },
      invalid: {
        required: '{{fieldName}} is required!',
        invalid: '{{fieldName}} is invalid!',
        load_fail: 'Load {{field}} fail!',
        greater_than_equal_0: 'must be greater than or equal to 0',
        greater_than_equal: 'must be greater than or equal',
      },
      information: {
        information: 'Information',
        title: 'Title',
        subtitle: 'Subtitle',
        content: 'Content',
        type: 'Placement',
        english: 'English',
        vietnamese: 'Vietnamese',
        type_guide: 'Instructions for choosing where to place',
        type_guide_pages: 'In information page',
        type_guide_home_header: 'On page header',
        type_guide_home_body: 'On homepage body',
        type_guide_product_details: 'In product detail page',
        type_guide_shopping_cart: 'In shopping cart page',
        type_guide_detail_pages: 'This will be used for the information page.',
        type_guide_detail_home_header:
          'This will be displayed in the header (top part of the website).',
        type_guide_detail_home_body:
          'This will be displayed in the middle of the homepage.',
        type_guide_detail_product_details:
          'This will be displayed in the product page.',
        type_guide_detail_shopping_cart:
          'This will be displayed in the shopping cart page.',
        articles: {
          shopping_with_nailzy: 'Shopping with Nailzy',
          general_information: 'General information',
          payments: 'Payments',
          orders_shipping: 'Orders & Shipping',
          returns_refunds: 'Returns & Refunds',
          offers_promotions: 'Offers & Promotions',
        },
        section: 'Section',
      },
      change_mail: {
        input_mail_to_confirm: 'Input your password to confirm',
        input_email: 'Input email',
        input_email_to_change: 'Enter the email you want to change',
      },
      change_phone: {
        otp_form_resent_code_after: 'Resend code after',
        add_phone_success: 'Add phone number successful',
        add_phone_fail: 'Add phone number fail',
        update_phone_success: 'Update phone number successful',
        update_phone_fail: 'Update phone number fail',
        send_otp_error: 'Send verification code error',
        input_your_phone_number: 'Input your phone number',
        phone_number_you_want_to_change:
          'Input the phone number you want to change',
        verify_OTP_code: 'Verify OTP code',
        code_has_been_sent:
          'Verification code has been sent to the phone number',
        verify_successful: 'Verify successful',
        phone_number_has_been_changed: 'The phone number has been changed',
      },
      store: {
        store_details: 'Store details',
        business_type: 'Business type',
        business_type_company: 'Company',
        business_type_individual: 'Individual',
        legal_business_name: 'Legal business name',
        tax_id: 'Tax ID (EIN, ITIN, or SSN)',
        upload_document: 'Upload documents',
        image_limit_error: 'Number of images is limit to 6',
        upload_images_condition_1: 'Dimensions: 2000 px width x 769 px height',
        upload_images_condition_2: 'Maximum: 6 images, file size 2.0 MB each',
        upload_images_condition_3: 'Supported file types: JPG, PNG, WEPB',
        document: 'Document',
        store_status: 'Store status',
        store_status_desc:
          'You can provide documents to verify your store here.',
        documents: 'Documents',
        banner_desc: 'Banners will be displayed in your store page.',
        fbn: 'Fulfilled by Nailzy (FBN)',
        seller_stocks_and_fulfill: 'Fulfilled by seller',
        fulfillment_type: 'Fulfillment type',
      },
      account: {
        update_profile_error: 'Update profile error',
        profile_picture: 'Profile picture',
        security_subtitle: 'You can add or update your sign-in methods.',
        social_linking_title: 'Social links',
        social_linking_subtitle:
          'Sign in faster by linking your social media to your account.',
      },
      coupon: {
        coupon: 'Coupon',
        coupon_code: 'Coupon code',
        percentage: 'Percentage',
        fixed_amount: 'Fixed amount',
        discount_value: 'Discount value',
        quantity: 'Quantity',
        selection: 'Target selection',
        target: 'Target',
        apply_to: 'Apply to',
        all_products: 'All products',
        specific_cates: 'Specific categories',
        specific_sellers: 'Specific sellers',
        specific_brands: 'Specific brands',
        requirements: 'Requirements',
        minimum_purchase_requirements: 'Minimum purchase requirements',
        minimum_purchase_amount: 'By minimum purchase amount',
        purchase_quantity: 'By minimum purchase quantity',
        target_customer: 'Target customer',
        customer_eligibility: 'Customer eligibility',
        all_customers: 'All customers',
        specific_customers: 'Specific customers',
        limit: 'Limit',
        usage_limit: 'Usage limit',
        one_use_per_customer: 'One use per customer',
        multiple_uses_per_customer: 'Multiple uses per customer',
        numbers_of_uses: 'Numbers of uses',
        valid_from: 'Valid from',
        valid_until: 'Valid until',
        excludes: 'Exceptions',
        excludes_by: 'Exclude by',
        no_exception: 'No exceptions',
        specific_cat: 'Specific category',
        no_target: 'No target',
        specific_seller: 'Specific seller',
        specific_brand: 'Specific brand',
        specific_product: 'Specific product',
        specific_promotion: 'Specific promotion',
        please_select_target: 'Please select target!',
      },
    },
    filter: {
      filters: 'Filters',
      joined_date_range: 'Joined date range',
      role: 'Role',
    },
    reviews: {
      review: 'Review',
      rating: 'Rating',
      tags: 'Tags',
      reviews_count: 'reviews',
      dissatisfied: 'Dissatisfied',
      acceptable: 'Acceptable',
      good: 'Good',
      satisfied: 'Satisfied',
      very_satisfied: 'Very satisfied',
      product_quality: 'Product quality',
      delivery_packaging: 'Shipping & packaging',
      reasonable_price: 'Price',
      enthusiastic_seller: 'Seller',
      other_reason: 'Other',
      add_review_success: 'Add review success',
      edit_review: 'Edit review',
    },
    customer: {
      role_type: 'Role type',
      auth_provider: 'Auth providers',
      business_phone: 'Business phone',
      business_name: 'Business name',
      amount_spent: 'Amount spent',
      nail_supply: 'Nail Supplier',
      nail_tech: 'Nail Technician',
      salon_owner: 'Salon Owner',
      other: 'Other',
      create_customer: 'Add customer',
      edit_customer: 'Edit customer',
      delete_customer: 'Delete customer',
      delete_customer_note:
        'Note: Deleting this customer will permanently delete all data and information associated with their account.',
      delete_customer_reason: 'Reason for deleting',
      send_email_reset_pass: 'Send email reset password',
      send_email_reset_pass_success: 'Send email successfully!',
      send_email_reset_pass_fail: 'Send email failed!',
      export_customer: 'Export customers',
      please_select_add_customer: 'Please select customer or add new address',
      referral_code: 'Referral code',
      referrals: 'Referrals',
      referred_by: 'Referred by',
    },
    sellers: {
      add_seller: 'Add seller',
      seller: 'Seller',
      seller_selected: 'sellers selected',
      no_seller_found: 'No seller found!',
      edit_store: 'Edit store details',
      edit_seller: 'Edit seller profile',
      edit_bank: 'Edit bank information',
      edit_warehouse: 'Edit warehouse',
      create_warehouse: 'Create warehouse',
      delete_seller: 'Delete seller',
      seller_profile: 'Seller profile',
      total_orders: 'Total Orders',
      total_sales: 'Total Sales',
      completed_orders: 'Completed Orders',
      completed_sales: 'Completed Sales',
      total_orders_decs: 'The total number of orders',
      total_sales_decs: 'The total value of all orders',
      completed_orders_decs: 'The number of orders marked as completed',
      completed_sales_decs: 'The total value of all completed orders',
      store_address: 'Store address',
      warehouse: 'Warehouse',
    },
    skills: {
      skill: 'Skill',
      skill_name: 'Skill name',
      no_skill_found: 'No skill found!',
      skill_selected: 'skills selected',
      add_skill: 'Add skill',
    },
    warehouses: {
      page_sub_title:
        'Manage the places you stock inventory, fulfill order, and sell products.',
      warehouse_name: 'Warehouse name',
      warehouse_address: 'Address',
      warehouse_status: 'Warehouse status',
      product_variant: 'Total product variants',
      inventory_quantity: 'Inventory quantity',
    },
    packages: {
      package: 'Package',
      packages: 'Packages',
      packages_decs:
        'Predefined package templates used when you buy shipping labels.',
      edit_package: 'Edit package',
      add_package: 'Add package',
      package_name: 'Package name',
      default_package: 'Default package',
      default_package_label:
        'Used to calculate live shipping rates at checkout.',
      name: 'Name',
      length: 'Length',
      width: 'Width',
      height: 'Height',
      weight: 'Weight',
    },
    draft_carts: {
      draft_carts: 'Draft carts',
      draft_cart: 'Draft cart',
      add_draft_carts: 'Add draft cart',
      save_draft: 'Save draft',
    },
    shipping: {
      shipping_rates: 'Shipping rates',
      shipping_desc:
        'Config shipping fees at checkout and manage available shipping zones.',
      live_rate: 'Live rates',
      live_rate_desc:
        'Shipping fees automatically determined at checkout by a third-party service.',
      flat_rate: 'Flat rates',
      flat_rate_desc: 'Charge a specific shipping amount at checkout.',
      shipping_zones: 'Shipping zones',
      shipping_zones_desc: 'Where orders can be shipped to.',
      carrier_name: 'Carrier name',
      amount_estimated: 'Amount & estimated delivery days',
      calculated: 'To be calculated',
      rate_name: 'Rate name',
      estimated_days: 'Estimated delivery day',
      conditions: 'Conditions',
      amount: 'Amount',
      add_flat_rate: 'Add flat rate',
      update_flat_rate: 'Update flat rate',
      base_on_price: 'Based on order price',
      base_on_weight: 'Based on order weight',
      min_price: 'Minimum price',
      max_price: 'Maximum price',
      min_weight: 'Minimum weight',
      max_weight: 'Maximum weight',
      condition_pricing: 'Conditional pricing',
      manage_shipping_zones: 'Manage shipping zones',
      countries_and_states: 'Countries & states',
      states: 'States',
      confirm_update_shipping_rate: 'Do you want to change shipping rate to ',
      update_shipping_rate: 'Change shipping rate',
      delete_shipping_rate: 'Delete shipping rate',
      min_price_must_grater_than_0:
        'Min price must be greater than or equal to 0',
      max_price_must_grater_than_min:
        'Max price must be greater than or equal to Min price',
      min_weight_must_grater_than_0:
        'Min weight must be greater than or equal to 0',
      max_weight_must_grater_than_min:
        'Max weight must be greater than or equal to Min weight',
    },
    system_users: {
      system_users: 'System users',
      system_users_decs:
        'Manage and assign roles and permissions for internal individuals.',
      roles: 'Roles',
      roles_decs:
        'With roles, you can manage permissions by granting or denying access to specific features.',
      add_role: 'Add role',
      add_system_user: 'Add system user',
      edit_system_user: 'Edit system user',
      edit_role: 'Edit role',
      role_name: 'Role name',
      is_super_admin: 'Is Super Admin?',
      permissions: 'Permissions',
      action_get: 'VIEW',
      action_post: 'CREATE',
      action_put: 'UPDATE',
      action_delete: 'DELETE',
      role_and_permission: 'Roles & permissions',
    },
  },
};

export default en;
