import { ReactNode } from 'react';
import { UseFormReturnType } from '@mantine/form';
import { Grid, Select } from '@mantine/core';
import { DatePicker, DatePickerInput } from '@mantine/dates';
import { t } from 'i18next';
import {
  BANNER_TARGET_INFO,
  BannerFilterFormType,
  BannerTargetType,
} from 'types';
import { useAuth } from 'contexts/AuthContext';
import StatusSelect from 'components/MantineUI/Selects/StatusSelect';
import { useParams } from 'react-router-dom';
type PropType = {
  children?: ReactNode;
  className?: string;
  onApply?: (data: any) => void;
  form: UseFormReturnType<BannerFilterFormType>;
};
const BannerFilterForm = (props: PropType) => {
  // ### CONSTANTs

  const { className = '', form } = props;
  const { getInputProps } = form || {};
  // ### FUNCTIONs
  const span = { base: 12 };
  // ### RENDERs
  return (
    <div className={className}>
      <Grid>
        <Grid.Col span={span}>
          <StatusSelect
            nativeSelect={false}
            hidePending
            required={false}
            {...getInputProps('status')}
          />
        </Grid.Col>
        <Grid.Col span={span}>
          <DatePickerInput
            label={t('promotions.start_date')}
            placeholder={t('general.select')}
            key={form.key('start_date')}
            {...form.getInputProps('start_date')}
            onChange={(v) =>
              form.setValues((prev) => ({ ...prev, start_date: v }))
            }
          />
        </Grid.Col>
        <Grid.Col span={span}>
          <DatePickerInput
            label={t('promotions.end_date')}
            placeholder={t('general.select')}
            key={form.key('end_date')}
            {...form.getInputProps('end_date')}
            onChange={(v) =>
              form.setValues((prev) => ({ ...prev, end_date: v }))
            }
          />
        </Grid.Col>
        <Grid.Col span={span}>
          <Select
            placeholder={t('general.select')}
            label={t('form.coupon.target')}
            {...getInputProps('type')}
            data={[
              {
                label: t(BANNER_TARGET_INFO[BannerTargetType.NO_TARGET]?.label),
                value: BannerTargetType.NO_TARGET,
              },
              {
                label: t(BANNER_TARGET_INFO[BannerTargetType.CATEGORY]?.label),
                value: BannerTargetType.CATEGORY,
              },
              {
                label: t(BANNER_TARGET_INFO[BannerTargetType.BRAND]?.label),
                value: BannerTargetType.BRAND,
              },
              {
                label: t(BANNER_TARGET_INFO[BannerTargetType.SELLER]?.label),
                value: BannerTargetType.SELLER,
              },
              {
                label: t(BANNER_TARGET_INFO[BannerTargetType.PRODUCT]?.label),
                value: BannerTargetType.PRODUCT,
              },
              {
                label: t(BANNER_TARGET_INFO[BannerTargetType.PROMOTION]?.label),
                value: BannerTargetType.PROMOTION,
              },
            ]}
          />
        </Grid.Col>
      </Grid>
    </div>
  );
};
export default BannerFilterForm;
