import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { Anchor, Button } from '@mantine/core';
import IconFA from 'components/common/IconFA';
import {
  SectionBody,
  SectionHeader,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import { CommonTable } from 'components/MantineUI/CommonTable';
import PageLayout from 'components/MantineUI/PageLayout';
import ListPageSkeleton from 'components/MantineUI/Skeleton/ListPageSkeleton';
import SkeletonWrapper from 'components/MantineUI/Skeleton/SkeletonWrapper';
import { t } from 'i18next';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CommonTableColumn, RoleItem } from 'types';
import { usePaginationParams } from 'hooks/usePaginationParams';
import { useDeleteSystemUser, useSystemUserList } from 'apis/UserApis';
import dayjs from 'dayjs';
import DropdownButton from 'components/MantineUI/Buttons/DropdownButton';
import StatusBadge from 'components/MantineUI/StatusBadge';
import { DATETIME_FORMAT } from 'constants/format';
import { faLock } from '@fortawesome/pro-light-svg-icons';
import UserDetailsModal from './components/UserDetailsModal';
import { phoneFormatter } from 'helpers/utils';
import DeleteWarningModal from 'components/common/DeleteWarningModal';
import RoleDetailsModal from './roles/components/RoleDetailsModal';

const SystemUserPage = () => {
  // ### CONSTANTs
  const navigate = useNavigate();
  const { page, limit, onPaginationChange } = usePaginationParams();
  const [selected, setSelected] = useState<any>();
  const [selectedRole, setSelectedRole] = useState<RoleItem>();
  const [deleteUser, setDeleteUser] = useState<any>();
  const { data, isLoading, isRefetching } = useSystemUserList({ page, limit });
  const { mutate: deleteUserAPI, isPending: deleting } = useDeleteSystemUser();
  const total = data?.data?.total || 0;
  const [term, setTerm] = useState<any>();
  const columns: CommonTableColumn[] = [
    {
      accessor: 'name',
      Header: t('general.name'),
      Cell: (row) => (
        <Anchor component={Link} to={`/system-users/${row?._id}`} size="sm">
          {row.full_name}
        </Anchor>
      ),
    },
    {
      accessor: 'email',
      Header: t('register.form.email'),
      Cell: (row) => row.email || '--',
    },
    {
      accessor: 'phone',
      Header: t('general.phone'),
      Cell: (row) => phoneFormatter(row.phone_number) || '--',
    },
    {
      accessor: 'role',
      Header: t('filter.role'),
      Cell: (row) =>
        row?.permission?.name ? (
          <Anchor onClick={() => setSelectedRole(row?.permission)} size="sm">
            {row?.permission?.name}
          </Anchor>
        ) : (
          '--'
        ),
    },
    {
      accessor: 'created_by',
      Header: t('general.created_by'),
      Cell: (row) => row?.created_by_user?.full_name || '--',
    },
    {
      accessor: 'created_on',
      Header: t('general.created_date'),
      Cell: (row: RoleItem) =>
        row.created_on &&
        dayjs.unix(row.created_on).format(DATETIME_FORMAT.MMMDDYYYY),
    },
    {
      accessor: 'status',
      Header: t('general.status'),
      Cell: (row: RoleItem) =>
        row.status && <StatusBadge status={row.status} />,
    },
    {
      accessor: 'actions',
      Cell: (row: RoleItem) => (
        <DropdownButton
          button={
            <Button variant="subtle" color="gray" size="xs">
              <IconFA icon={'ellipsis'} />
            </Button>
          }
          dropdownData={[
            {
              key: 'edit',
              label: t('general.edit'),
              onClick: () => setSelected(row),
            },
            { isDivider: true },
            {
              key: 'delete',
              label: t('general.delete'),
              color: 'red',
              onClick: () => setDeleteUser(row),
            },
          ]}
        />
      ),
    },
  ];
  // ### FUNCTIONs
  const onDeleteUser = () => {
    deleteUserAPI(deleteUser?._id, {
      onSuccess: () => {
        setDeleteUser(undefined);
      },
    });
  };
  // ### RENDERs
  return (
    <SkeletonWrapper show={isLoading} skeleton={<ListPageSkeleton />}>
      <PageLayout
        title={t('system_users.system_users')}
        breadCrumds={[{ title: t('system_users.system_users') }]}
      >
        <SectionWrapper>
          <SectionHeader
            title={t('system_users.system_users')}
            subtitle={t('system_users.system_users_decs')}
            actionButtons={
              <>
                <Button
                  onClick={() => navigate('/system-users/roles')}
                  variant="subtle"
                  leftSection={<IconFA icon={faLock} />}
                >
                  {t('system_users.role_and_permission')}
                </Button>
                <Button
                  onClick={() => setSelected({})}
                  leftSection={<IconFA icon={faPlus} />}
                >
                  {t('system_users.add_system_user')}
                </Button>
              </>
            }
          ></SectionHeader>
          <SectionBody>
            <CommonTable
              loading={isRefetching}
              data={data?.data?.results || []}
              columns={columns}
              pagination={{ page, limit, total, onPaginationChange }}
            />
          </SectionBody>
        </SectionWrapper>
        <UserDetailsModal
          data={selected}
          onClose={() => setSelected(undefined)}
          opened={!!selected}
        />
        <RoleDetailsModal
          opened={!!selectedRole}
          onClose={() => setSelectedRole(undefined)}
          data={selectedRole}
        />
        <DeleteWarningModal
          onShow={!!deleteUser}
          name={deleteUser?.full_name}
          onClose={() => setDeleteUser(undefined)}
          onDelete={onDeleteUser}
          isLoading={deleting}
        />
      </PageLayout>
    </SkeletonWrapper>
  );
};
export default SystemUserPage;
