import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faArrowsUpDownLeftRight,
  faCaretDown,
  faCaretUp,
  faChartPie,
  faCircleQuestion,
  faHouse,
  faRotateLeft,
  faArrowDownShortWide,
  faSquare,
  faSquareCheck,
  faTrash,
  faArrowsRotate,
  faAdd,
  faUpload,
  faBan,
  faImage,
  faCheckCircle,
  faSave,
  faArchive,
  faClock,
  faCheck,
  faFilter,
  faMagnifyingGlass,
  faAnglesUpDown,
  faChevronDown,
  faHome,
  faUserCircle,
  faSignOutAlt,
  faLanguage,
  faQuestionCircle,
  faEnvelope,
  faPhone,
  faLock,
  faEllipsis,
  faChevronRight,
  faFile,
  faArrowDownToLine,
  faTrashCan,
  faGlobe,
  faNoteSticky,
  faSearch,
  faStar,
  faDownload,
  faPaperclip,
  faAt,
  faStore,
  faEdit,
  faEye,
  faMap,
  faPlus,
  faCircle,
  faRotate,
  faTruck,
  faCircleCheck,
  faChevronLeft,
  faChevronUp,
  faGripDotsVertical,
  faBox,
  faShoppingCart,
  faPercentage,
  faBolt,
  faWarehouse,
  faBullhorn,
  faTag,
  faDollarSign,
  faUsers,
  faNewspaper,
  faUserGraduate,
  faGear,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
  faArrowsUpDownLeftRight,
  faHouse,
  faChartPie,
  faRotateLeft,
  faCircleQuestion,
  faCaretUp,
  faCaretDown,
  faArrowDownShortWide,
  faSquare,
  faSquareCheck,
  faTrash,
  faArrowsRotate,
  faAdd,
  faUpload,
  faBan,
  faImage,
  faCheckCircle,
  faSave,
  faArchive,
  faClock,
  faCheck,
  faFilter,
  faMagnifyingGlass,
  faAnglesUpDown,
  faChevronDown,
  faHome,
  faUserCircle,
  faSignOutAlt,
  faLanguage,
  faQuestionCircle,
  faEnvelope,
  faPhone,
  faLock,
  faEllipsis,
  faChevronRight,
  faFile,
  faArrowDownToLine,
  faTrashCan,
  faGlobe,
  faNoteSticky,
  faSearch,
  faStar,
  faDownload,
  faPaperclip,
  faAt,
  faStore,
  faEdit,
  faEye,
  faMap,
  faPlus,
  faCircleQuestion,
  faCircle,
  faRotate,
  faTruck,
  faCircleCheck,
  faChevronLeft,
  faChevronUp,
  faGripDotsVertical,
  faArrowsUpDownLeftRight,
  faBox,
  faShoppingCart,
  faPercentage,
);
export const solidIcons = {
  'arrows-up-down-left-right': faArrowsUpDownLeftRight,
  'caret-down': faCaretDown,
  'caret-up': faCaretUp,
  'chart-pie': faChartPie,
  'circle-question': faCircleQuestion,
  house: faHouse,
  'rotate-left': faRotateLeft,
  'arrow-down-short-wide': faArrowDownShortWide,
  square: faSquare,
  'square-check': faSquareCheck,
  trash: faTrash,
  'arrows-rotate': faArrowsRotate,
  add: faAdd,
  upload: faUpload,
  ban: faBan,
  image: faImage,
  'check-circle': faCheckCircle,
  save: faSave,
  archive: faArchive,
  clock: faClock,
  check: faCheck,
  filter: faFilter,
  'magnifying-glass': faMagnifyingGlass,
  'angles-up-down': faAnglesUpDown,
  'chevron-down': faChevronDown,
  home: faHome,
  'user-circle': faUserCircle,
  'sign-out-alt': faSignOutAlt,
  language: faLanguage,
  'question-circle': faQuestionCircle,
  envelope: faEnvelope,
  phone: faPhone,
  lock: faLock,
  ellipsis: faEllipsis,
  'chevron-right': faChevronRight,
  file: faFile,
  'arrow-down-to-line': faArrowDownToLine,
  'trash-can': faTrashCan,
  globe: faGlobe,
  'note-sticky': faNoteSticky,
  search: faSearch,
  star: faStar,
  download: faDownload,
  paperclip: faPaperclip,
  at: faAt,
  store: faStore,
  edit: faEdit,
  eye: faEye,
  map: faMap,
  plus: faPlus,
  circle: faCircle,
  rotate: faRotate,
  truck: faTruck,
  'circle-check': faCircleCheck,
  'chevron-left': faChevronLeft,
  'chevron-up': faChevronUp,
  'grip-dots-vertical': faGripDotsVertical,
  box: faBox,
  'shopping-cart': faShoppingCart,
  percentage: faPercentage,
  bolt: faBolt,
  warehouse: faWarehouse,
  bullhorn: faBullhorn,
  tag: faTag,
  'dollar-sign': faDollarSign,
  users: faUsers,
  newspaper: faNewspaper,
  'user-graduate': faUserGraduate,
  gear: faGear,
  'triangle-exclamation': faTriangleExclamation,
};
