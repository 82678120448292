import { StatusEnum } from 'types';
import StatusBadge from 'components/MantineUI/StatusBadge';
import { ComboboxItem, Flex } from '@mantine/core';
type Props = ComboboxItem & {
  label: string;
  value: string;
  status: StatusEnum;
};

export const RenderOptionWithStatus = (props: Props) => {
  const { label, status } = props;
  return (
    <Flex gap={'xs'} align={'center'}>
      <span>{label}</span>
      {status && status !== StatusEnum.ACTIVE && (
        <StatusBadge status={status} />
      )}
    </Flex>
  );
};

export default RenderOptionWithStatus;
