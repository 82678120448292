import {
  Combobox,
  Loader,
  MultiSelect,
  Select,
  useCombobox,
} from '@mantine/core';
import { useInfiniteQuery } from '@tanstack/react-query';
import { apiCallV2 } from 'apis/ApiCall';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
type PropType = {
  route: string;
  optionTransformer?: (data: any) => any[];
  className?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  errorMessage?: string;
  limit?: number;
  renderOption?: any;
  params?: any;
} & (
  | {
      multiple?: false;
      value: string | null;
      onChange: (id: string) => void;
    }
  | {
      multiple: true;
      value: string[] | null;
      onChange: (ids: string[]) => void;
    }
);
const CommonSelect = (props: PropType) => {
  // ### CONSTANTs
  const controller = new AbortController();
  const {
    route = '',
    className = '',
    value,
    label,
    placeholder,
    onChange,
    multiple,
    required,
    errorMessage,
    renderOption,
    limit = 25,
    params = {},
    optionTransformer = (data) =>
      data?.results?.map((x: any) => ({
        label: x?.name,
        value: x?._id,
      })),
  } = props;
  // const [page, setPage] = useState(1);
  const [data, setData] = useState<any[]>([]);
  const LIMIT = multiple ? 1000 : limit;
  const {
    data: res,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: [route, 'infinity'],
    initialPageParam: 1,
    queryFn: ({ pageParam = 1 }) =>
      apiCallV2({
        url: route,
        params: { limit: LIMIT, page: pageParam, ...params },
        signal: controller.signal,
      } as any),
    getNextPageParam: (lastPage: any, pages) => {
      const hasMore = lastPage?.data?.total > pages.length * LIMIT;
      const nextPage = pages.length + 1;
      return hasMore ? nextPage : undefined;
    },
    enabled: !!route,
  });

  useEffect(() => {
    let newData: any[] = [];
    res?.pages?.map((page: any) =>
      optionTransformer(page?.data)?.map((item: any) => newData.push(item)),
    ) || [];
    setData(newData);
  }, [res]);
  // ### FUNCTIONs
  // ### RENDERs
  const generalProps = {
    key: route,
    error: !!errorMessage,
    required: required,
    label: label,
    searchable: true,
    renderOption: renderOption,
    // disabled: isLoading,
    placeholder: placeholder,
    data: data,
    rightSection: isLoading ? <Loader size={15} /> : undefined,
  };
  const handleDropdownScroll = (event: any) => {
    const target = event.currentTarget;

    if (target.scrollHeight - target.scrollTop <= target.clientHeight + 1) {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }
  };

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });
  const options = data
    ?.filter((x) =>
      multiple ? !value?.includes(x?.value) : x?.value !== value,
    )
    .map((item, index) => {
      return (
        <>
          <Combobox.Option value={item?.value} key={item?.value}>
            {item?.label}
          </Combobox.Option>
          {hasNextPage && index === data?.length - 1 ? (
            <Combobox.Option disabled value="loading" key={'loading'}>
              <div className="w-full flex items-center gap-2 justify-center">
                <Loader size={12} />
                {t('general.loading')}
              </div>
            </Combobox.Option>
          ) : null}
        </>
      );
    });
  const onClickOption = (id: string) => {
    if (multiple) {
      onChange?.([...(value || []), id]);
    } else {
      onChange?.(id as string);
      combobox.targetRef.current?.blur();
      combobox.closeDropdown();
    }
  };
  return (
    <div className={className}>
      {multiple ? (
        <MultiSelect
          {...generalProps}
          hidePickedOptions
          value={value as string[]}
          onChange={(value) => {
            onChange?.(value as string[]);
          }}
        />
      ) : (
        <Combobox
          position="bottom"
          store={combobox}
          onOptionSubmit={onClickOption}
        >
          <Combobox.Target>
            <Select
              {...generalProps}
              value={value}
              onFocus={() => combobox.openDropdown()}
              dropdownOpened={false}
            />
          </Combobox.Target>

          <Combobox.Dropdown>
            <Combobox.Options
              onScroll={handleDropdownScroll}
              mah={200}
              style={{ overflowY: 'auto' }}
            >
              {isLoading ? (
                <Combobox.Empty>{t('general.loading')}</Combobox.Empty>
              ) : (
                options
              )}
            </Combobox.Options>
          </Combobox.Dropdown>
        </Combobox>
      )}
    </div>
  );
};
export default CommonSelect;
