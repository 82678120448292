import RenderOptionWithStatus from 'components/common/inputs/RenderOptionWithStatus';
import { t } from 'i18next';
import { InputError } from '@mantine/core';
import { useAuth } from 'contexts/AuthContext';
import { checkPermission } from 'helpers/utils';
import SingleSelect, { SelectItem, SingleSelectProps } from './SingleSelect';
import { StatusEnum } from 'types';
import MultiSelect from './MultiSelect';
type PropType =
  | {
      multiple: true;
      value: SelectItem[];
      onChange: (ids: SelectItem[]) => void;
      //
      className?: string;
      errorMessage?: string;
      required?: boolean;
    }
  | {
      multiple?: false;
      value: SelectItem;
      onChange: (id: SelectItem) => void;
      //
      className?: string;
      errorMessage?: string;
      required?: boolean;
    };
const BrandSelect = (props: SingleSelectProps & PropType) => {
  // ### CONSTANTs
  const { value, onChange, multiple, errorMessage, ...els } = props;
  // sellers
  const { user } = useAuth();
  const isGranted = checkPermission(user, '/brands');
  // ### FUNCTIONs
  // ### RENDERs
  const generalProps: SingleSelectProps = {
    route: '/admin/brands',
    label: t('products.brand'),
    placeholder: t('products.select_brand'),
    disabled: !isGranted,
    renderOption: (option: any) => <RenderOptionWithStatus {...option} />,
    //
    optionTransformer: (data: any) =>
      data?.results?.map((x: any) => ({
        label: x.name,
        value: x._id,
        status: x.status,
      })),
    ...els,
  };
  const renderInput = () => {
    if (multiple) {
      return (
        <MultiSelect
          {...generalProps}
          selected={value}
          onChange={(value) => {
            onChange?.(value as SelectItem[]);
          }}
        />
      );
    } else {
      return (
        <SingleSelect
          {...generalProps}
          selected={value}
          onChange={(value) => {
            onChange?.(value as SelectItem);
          }}
        />
      );
    }
  };
  return (
    <div>
      {renderInput()}
      {errorMessage ? (
        <InputError className="mt-1">{errorMessage}</InputError>
      ) : null}
    </div>
  );
};
export default BrandSelect;
