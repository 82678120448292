import { Button, Drawer, Flex, Grid, Select, Switch } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { UseFormReturnType } from '@mantine/form';
import StatusSelect from 'components/MantineUI/Selects/StatusSelect';
import { CustomerTitleTypeOptions } from 'constants/customers';
import { t } from 'i18next';
import { ReactNode } from 'react';
type PropType = {
  opened: boolean;
  onClose: () => void;
  form: UseFormReturnType<any>;
  onSave: () => void;
  onReset: () => void;
};
const CustomerFilter = (props: PropType) => {
  // ### CONSTANTs
  const { form, opened, onClose, onSave, onReset } = props;
  // ### FUNCTIONs

  // ### RENDERs
  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      position="right"
      className="pt-0"
      title={t('filter.filters')}
    >
      <Grid>
        <Grid.Col span={{ base: 12 }}>
          <StatusSelect
            nativeSelect={false}
            required={false}
            {...form.getInputProps('status')}
            hidePending
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12 }}>
          <DatePickerInput
            type="range"
            label={t('filter.joined_date_range')}
            placeholder={t('general.select')}
            key={form.key('date_range')}
            {...form.getInputProps('date_range')}
            onChange={(v) =>
              form.setValues((prev) => ({ ...prev, date_range: v }))
            }
            maxDate={new Date()}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12 }}>
          <Select
            label={t('filter.role')}
            {...form.getInputProps('title_type')}
            data={CustomerTitleTypeOptions?.map((x) => ({
              ...x,
              label: t(x.label),
            }))}
            placeholder={t('general.select_role')}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12 }}>
          <Switch
            checked={form.getValues()?.is_referral}
            label={t('customer.referrals')}
            {...form.getInputProps('is_referral')}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12 }}>
          <Flex gap={'sm'} justify={'end'}>
            <Button onClick={() => onReset?.()} variant="default">
              {t('general.reset')}
            </Button>
            <Button onClick={() => onSave?.()}>{t('general.save')}</Button>
          </Flex>
        </Grid.Col>
      </Grid>
    </Drawer>
  );
};
export default CustomerFilter;
